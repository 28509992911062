<template>
  <div class="base-banner-full-bg relative">
    <div class="absolute inset-0 bg-black opacity-50 w-full h-full z-[1]" />
    <NuxtImg
      class="absolute inset-0 z-[0] h-full w-full object-cover"
      :alt="imageAlt"
      :src="imageSrc || ''"
      quality="90"
      loading="lazy"
    />
    <div class="container px-4 sm:px-6 lg:px-8 py-16 grid grid-cols-1 lg:grid-cols-2 relative z-[2] lg:min-h-[50rem] gap-4">
      <BaseTitle variant="white" level="h2">
        {{ title }}
      </BaseTitle>

      <div class="flex flex-col items-start justify-end text-left">
        <p class="my-6 text-brand-white text-xl">
          {{ subtitle }}
        </p>

        <BaseButtonLink
          size="xl"
          variant="white"
          :to="ctaLink"
          @click="onClickCta(ctaLink)"
        >
          {{ ctaText }}
        </BaseButtonLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButtonLink from '~/components/BaseButtonLink.vue';
import BaseTitle from '~/components/BaseTitle.vue';
import { useGTM } from '~/composables';
import { TRACKING_EVENTS } from '~/lib/constants';

export interface Props {
  title: string
  subtitle: string
  ctaText: string
  ctaLink: string
  imageSrc: string
  imageAlt: string
}

defineProps<Props>();

const { triggerEvent } = useGTM();
function onClickCta (url: string) {
  triggerEvent(TRACKING_EVENTS.CLICK_CTA_BANNER, { url });
}
</script>
